<template>
  <div class="hide-scroll">
    <v-list>
      <v-list-group
        v-for="(item, index) in topMenu"
        :key="index"
        :value="item.active"
        :append-icon="item.route ? '' : 'mdi-chevron-down'"
        active-class="active"
        @click="actions(item.title)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <span :style="{ color: item.color }"> {{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-if="!item.route">
          <v-list-group
            v-for="(sub, i) in item.items"
            :key="i"
            :value="sub.active"
            append-icon=""
            active-class="active"
          >
            <template v-slot:activator>
              <v-list-item-content @click="actionsSub(sub.title)" class="pl-3">
                <v-list-item-title>
                  <span :style="{ color: sub.color }"> {{ sub.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>
        </template>
      </v-list-group>
    </v-list>

    <v-list class="mt-4">
      <v-list-group
        v-for="(item, index) in crossBrandMenu"
        :key="index"
        :value="item.active"
        :append-icon="item.route ? '' : 'mdi-chevron-down'"
        active-class="active"
        @click="actions(item.title)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <span style="font-weight: bold" :style="{ color: item.color }">
                {{ item.title }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-if="!item.route">
          <v-list-group
            v-for="(sub, i) in item.items"
            :key="i"
            :value="sub.active"
            append-icon=""
            active-class="active"
          >
            <template v-slot:activator>
              <v-list-item-content @click="actionsSub(sub.title)" class="pl-3">
                <v-list-item-title>
                  <span :style="{ color: sub.color }"> {{ sub.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>
        </template>
      </v-list-group>
    </v-list>

    <!-- <ul
      v-if="brand !== '15' && brand !== '17'"
      class="sidebar-panel-nav mt-4 pl-2"
    >
      <li class="sub-c" @click="$emit('toPage', 'Special-Buy')">Special Buy</li>
    </ul> -->

    <ul class="sidebar-panel-nav mt-16 pl-2">
      <li class="sub-c" @click="$emit('showBottomMenu')">+ INFO</li>
      <div v-if="bottomMenu">
        <li
          class="sub-c"
          v-if="idBrand === '15'"
          @click="$emit('toPage', 'Confirm-Payment')"
        >
          {{ $t("menu.confirm-payment") }}
        </li>
        <li class="sub-c" @click="$emit('toPage', 'Order')">
          {{ $t("account.purchase.title") }}
        </li>
        <!-- <li class="sub-c" @click="$emit('toPage', 'Article')">
          {{ $t("menu.article") }}
        </li> -->
        <li class="sub-c" @click="$emit('toPage', 'Help')">
          {{ $t("menu.help") }}
        </li>
        <li class="sub-c" @click="$emit('toPage', 'Faq')">
          {{ $t("menu.faq") }}
        </li>
        <li class="sub-c" @click="$emit('toPage', 'Company')">
          {{ $t("menu.company") }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SidebarItem",
  props: {
    bottomMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["menuItems"]),
    topMenu() {
      return this.menuItems.filter(
        (item) => item.id_brand === process.env.VUE_APP_BRAND
      );
    },
    crossBrandMenu() {
      return this.menuItems.filter(
        (item) => item.id_brand !== process.env.VUE_APP_BRAND
      );
    },
  },
  data() {
    return {
      brand: process.env.VUE_APP_BRAND,
    };
  },
};
</script>
