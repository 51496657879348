import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/pages/:id',
    name: 'Pages',
    component: () => import('../views/Pages/index.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forget-password',
    name: 'Forget-Password',
    component: () => import('../views/ForgetPassword.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/shop/product/:id/:name',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/shop/:brand/:category/:id',
    name: 'Shop',
    // component: () => import('../views/Shop.vue')
    component: () => import('../views/ShopV2.vue')
  },
  {
    path: '/shop/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/article/:slug',
    name: 'Article-View',
    component: () => import('../views/ArticleView.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help/Help.vue'),
  },
  {
    path: '/help/:category',
    name: 'Help-Category',
    component: () => import('../views/Help/HelpCategory.vue'),
  },
  {
    path: '/special-buy',
    name: 'Special-Buy',
    component: () => import('../views/SpecialBuy/index.vue')
  },
  {
    path: '/special-buy/:slug',
    name: 'Special-Buy-View',
    component: () => import('../views/SpecialBuy/SpecialBuyView.vue')
  },
  {
    path: '/help/:category/:slug',
    name: 'Help-Detail',
    component: () => import('../views/Help/HelpDetail.vue'),
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue'),
    children: [
      {
        path: '',
        name: 'My-Account',
        component: () => import('../views/Members/MyAccount.vue'),
      },
      {
        path: 'access-data',
        name: 'Access-Data',
        component: () => import('../views/Members/AccessData.vue'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../views/Members/Order.vue'),
      },
      {
        path: 'order/:id',
        name: 'Order-Detail',
        component: () => import('../views/Members/OrderDetail.vue'),
      },
      {
        path: 'personal-info',
        name: 'Info',
        component: () => import('../views/Members/PersonalInfo.vue'),
      },
      {
        path: 'confirm-payment',
        name: 'Confirm-Payment',
        component: () => import('../views/Members/ConfirmPayment.vue'),
      },
      {
        path: 'confirm-payment/:id',
        name: 'Confirm-Payment-id',
        component: () => import('../views/Members/ConfirmPayment.vue'),
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('../views/Members/Address.vue'),
      },
      {
        path: 'wishlist',
        name: 'Wishlist',
        component: () => import('../views/Members/Wishlist.vue'),
      },
      {
        path: 'my-review',
        name: 'MyReview',
        component: () => import('../views/Members/MyReview.vue')
      },
      {
        path: 'my-discussion',
        name: 'MyDiscussion',
        component: () => import('../views/Members/MyDiscussion.vue')
      }
    ] 
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('../views/Notification.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("../views/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
