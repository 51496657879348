import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Mixins from './mixins'
import VueTouch from 'vue-touch'
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import i18n from './i18n'
import VueFacebookPixel from 'vue-facebook-pixel'
import LazyLoadDirective from "./directives/LazyLoadDirective";


Vue.directive("lazyload", LazyLoadDirective);
 
Vue.use(VueFacebookPixel)

Vue.use(VueSocialSharing)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(VueTouch)

Vue.mixin(Mixins)

new Vue({
  vuetify,
  store,
  router,

  beforeCreate () {
    this.$store.commit('initialiseStore')
    Vue.analytics.fbq.init(process.env.VUE_APP_FB_PIXEL_ID, {
      em: 'try.getraw@yahoo.com'
    })
  },

  i18n,
  render: h => h(App)
}).$mount('#app')
