<template>
  <div class="notif" v-if="notif">
    <v-card align="center" :color="bgColor">
      <div v-if="brand === '9'" class="img-notif-wrapper py-4 ">
        <img class="img-notif-logo" src="@/assets/heymale-logo.png" alt="heymale-logo">
      </div>
      <v-card-text class="mb-4">
        <p v-if="notif.title && notif.title !==''" class="text mb-5"> {{ notif.title }} </p>

        <p class="text-sub" v-html="notif.message"></p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="100%"
          id="notifButton"
          :color="primaryBtnColor"
          height="44"
          @click="submit()"
        >
          <span class="white--text"> {{notif.btnText}} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Notif',
  props: {
    notif: {
      type: Object,
      default: () => {
        return {
          title: '',
          message: 'Berhasil',
          action: '',
          btnText: 'OK',
        }
      }
    },
    primaryBtnColor: {
      type: String,
      default: '#000'
    },
    bgColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      brand: process.env.VUE_APP_BRAND
    }
  },
  methods: {
    submit () {
      if (this.notif.action !== '') {
        this.$emit('route', this.notif.action)
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
<style scoped>
.notif {
  background-color: #fff;
  border-radius: 10%;
}
.img-notif-wrapper {
  background-color: #000;
  text-align: center;
}
.img-notif-logo {
  width: 100%;
}
.text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.text-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
</style>