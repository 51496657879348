<template>
  <div class="notif" v-if="notif">
    <v-card align="center" :color="bgColor">
      <v-card-text class="mb-3">
        <p v-if="notif.title && notif.title !==''" class="text mb-8"> {{ notif.title }} </p>

        <p class="text-sub" v-html="notif.message"></p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="secondaryBtn"
          width="45%"
          id="secondaryBtn"
          :color="secondaryBtnColor"
          height="40"
          @click="$emit('close')"
        >
          <span class="black--text"> {{secondaryBtnText}} </span>
        </v-btn>
        <v-spacer v-if="secondaryBtn"></v-spacer>
        <v-btn
          :width="secondaryBtn ? '45%' :'100%'"
          id="primaryBtn"
          :color="primaryBtnColor"
          height="40"
          @click="submit()"
        >
          <span class="white--text"> {{notif.btnText}} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Popup',
  props: {
    notif: {
      type: Object,
      default: () => {
        return {
          title: '',
          message: 'Berhasil',
          action: '',
          btnText: 'OK',
        }
      }
    },
    primaryBtnColor: {
      type: String,
      default: '#000'
    },
    secondaryBtn: {
      type: Boolean,
      default: false
    },
    secondaryBtnText: {
      type: String,
      default: 'Not Now'
    },
    secondaryBtnColor: {
      type: String,
      default: '#fff'
    },
    bgColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      brand: process.env.VUE_APP_BRAND
    }
  },
  methods: {
    submit () {
      if (this.notif.action !== '') {
        if (this.$route.name !== this.notif.action) {
          this.$router.push({name: this.notif.action, query: {redirectTo : this.$route.name}})
        }
      }
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.notif {
  background-color: #fff;
  border-radius: 10%;
}
.img-notif-wrapper {
  background-color: #000;
  text-align: center;
}
.img-notif-logo {
  width: 100%;
}
.text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.text-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
</style>