<template>
  <v-app id="content-wrap">
    <Loader v-if="isLoading"/>
    <Header
      v-if="appBrand === '17' || appBrand === '15'"
      :isTransparentHeader="isTransparentHeader"
      @search="handleSearch"
      @showHide="setPanel(true)"
    /> 
    <NewHeader
      v-else
      :isTransparentHeader="isTransparentHeader"
      :isShowTab="isShowTab"
      :isShowNotif="isShowNotif"
      @search="handleSearch"
      @hideNotif="isShowNotif = false"
      @showHide="setPanel(true)"
    />

    <!-- <Header
      :isTransparentHeader="isTransparentHeader"
      @search="handleSearch"
      @showHide="setPanel(true)"
    />  -->

    <Search v-if="search" class="search-content" :style="searchStyle" @close="handleSearchClose" />
    <!-- <Search v-if="search" class="search-content" @close="handleSearchClose" /> -->

    <Sidebar :isPanelOpen="isSideBarOpen" @showHide="setPanel(false)">
      <SidebarItem :bottomMenu="bottomMenu" @showBottomMenu="showBottomMenu" @toPage="toPage($event)" />
    </Sidebar>
    <Snackbar />

    <div :style="contentWrapperStyle">
      <router-view class="mb-9" :style="contentStyle" :key="$route.fullPath"  />
    </div>

    <!-- <router-view class="mb-9" :key="$route.fullPath"  /> -->

    <v-footer
      id="footer"
      :style="footer && footer.color.font ? {'color': footer.color.font} : {'color': 'black'}"
      :color="footer && footer.color.background ? footer.color.background : 'white'"
    >
      <v-col
        cols="12"
        v-if="footer && route !== 'Cart' && route !== 'Checkout' "
        :style="footer && footer.color.background  ? {'background-color': footer.color.background} : {'background-color': 'white'}"
      >
       <v-layout wrap class="pl-4 pr-4">
          <v-flex xs12 sm4 md5 class="mt-5">
            <span class="footer-title-b-local"> {{footer.website_name}} </span>
            <div class="mt-4">
              <span class="custom-p" v-html="footer.deskripsi"></span>
            </div>
            <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
          </v-flex>
          <v-flex xs12 sm4 md4 class= "mt-5">
            <span class="footer-title-b-local"> FOLLOW US </span>
            <v-layout class="mt-4">
              <div v-for="(image, index) in footer.social_media" :key="index">
                <img v-bind:src="image.logo" width="25px" :alt="image.logo" @click="toLink(image.link)" class="mr-4">
              </div>
            </v-layout>
            <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
          </v-flex>
          <v-flex xs12 sm4 md3 class= "mt-5">
            <div>
              <span class="footer-title-local"> Metode Pembayaran </span>
              <v-layout wrap class="mt-4 payment-method">
                <v-flex xs12 class="text-center">
                  <img v-for="(image, index) in footer.metode_pembayaran" :key="index" v-bind:src="image.logo" width="35px" :alt="image.logo" class="mr-2">
                </v-flex>
              </v-layout>
              <v-divider v-if="isMobile" class="mt-4" :color="footer && footer.color.font ? footer.color.font : 'white'"></v-divider>
            </div>
            <div class="mt-5">
              <span class="footer-title-local"> Metode Pengiriman </span>
              <v-layout class="mt-4">
                <div>
                  <img v-bind:src="footer.metode_pengiriman.logo" width="32px" :alt="footer.metode_pengiriman.logo">
                </div>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-col>

      <v-col
        class="text-center pl-4 pr-4 pt-0"
        cols="12"
        :style="footer && footer.color.background  ? {'background-color': footer.color.background} : {'background-color': 'white'}"
      >
        <v-layout wrap>
          <v-flex xs12 class="footer-text-local mt-4">
            Copyright &copy; {{appName}}.  All Right Reserved
          </v-flex>
        </v-layout>
      </v-col>

    </v-footer>

    <v-btn
      v-if="footer"
      fab
      medium
      :color="getFloatButtonColor()"
      :class="isMobile ? 'float-button' : 'float-button-dekstop'"
      @click="openWhatsapp"
    >
      <!-- <v-icon color="#fff" large> fab fa-whatsapp </v-icon> -->
      <v-icon color="#fff" large> mdi-whatsapp </v-icon>
    </v-btn>

    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <LoginForm v-if="dialog" @close="close()" />
    </v-dialog>

    <v-dialog v-model="showNotif"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <Notif :notif="message" @route="handleRoute($event)" />
    </v-dialog>

    <v-dialog v-model="popup"
      width="300px"
      transition="dialog-bottom-transition"
    >
      <Popup 
        v-if="popup"
        :notif="popupNotif"
        :secondaryBtn="true"
        @close="popup = false" />
    </v-dialog>
  </v-app>
</template>

<script>
  import Header from './components/Header'
  import NewHeader from './components/Header/NewHeader'
  import Sidebar from './components/Sidebar'
  import Loader from '@/components/Loader'
  import Snackbar from '@/components/Snackbar'
  import LoginForm from '@/components/Login'
  import Search from '@/components/Search'
  import Notif from '@/components/Notif'
  import Popup from '@/components/Popup'
  import SidebarItem from '@/components/Sidebar/SidebarItem'
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'App',
    components: {
      Header,
      Sidebar,
      Loader,
      Snackbar,
      LoginForm,
      Search,
      Notif,
      Popup,
      SidebarItem,
      NewHeader
    },
    computed: {
      ...mapState([
        'isMobile',
        'isLoading',
        'isSideBarOpen',
        'menuItems',
        'isLogin',
        'token',
        'userName',
        'personalData',
        'activeTab'
      ]),
      dialog: {
        get: function () { return this.$store.getters.getLoginDialog },
        set:  function (newValue) { this.$store.commit('setLoginDialog', newValue) }
      },
      fullPath () {
        return this.$route.fullPath
      },
      route () {
        return this.$route.name ? this.$route.name : 'index'
      },
      searchStyle () {
        if (this.appBrand === '17' || this.appBrand === '15') {
          return {top: '65px'}
        } else {
          if (this.isShowTab && this.appBrand === '14') {
            if (this.isShowNotif) {
              if (this.route === 'Checkout' || this.route === 'Cart') {
                return {top: '105px'}
              }else {
                return {top: '141px'}
              }
            } else {
              return {top: '105px'}
            }
          } else {
             if (this.isShowNotif) {
              if (this.route === 'Checkout' || this.route === 'Cart') {
                return {top: '65px'}
              }else {
                return {top: '101px'}
              }
            } else {
              return {top: '65px'}
            }
          }
        }
      },
      contentWrapperStyle () {
        if (this.appBrand === '17' || this.appBrand === '15') {
          return {}
        } else {
          if (this.route === 'Home' || this.route === 'index') {
            if (this.isShowTab && this.appBrand === '14') {
              if (this.isShowNotif) {
                return {marginTop: '64px'}
              } else {
                return {marginTop: '36px'}
              }
            } else {
              if (this.isShowNotif) {
                return {marginTop: '36px'}
              } else {
                return {marginTop: '0px'}
              }
            }
          } else {
            return {}
          }
        }
      },
      contentStyle () {
        if (this.appBrand === '17' || this.appBrand === '15') {
          return {marginTop: '8px'}
        } else {
          if (this.isShowTab && this.appBrand === '14') {
            if (this.isShowNotif) {
              return {marginTop: '156px'}
            } else {
              return {marginTop: '120px'}
            }
          } else {
             if (this.isShowNotif) {
              return {marginTop: '108px'}
            } else {
              return {marginTop: '16px'}
            }
          }
        }
      }
    },
    watch: {
      fullPath () {
        this.search = false
      },
      isLogin (val) {
        if (val) {
          // this.getNotif()
          this.checkData()
        }
      },
      personalData (val) {
        if (this.isLogin) {
          let isComplete = this.isCompletePersonalData(val)

          if (!isComplete) {
            setTimeout(() => {
              if (this.fullPath === '/' || this.fullPath === '/cart') {
                this.popup = true
              }
            }, 500)
          }
        }
      },
      activeTab () {
        this.getMenus()
      },
    },
    data () {
      return {
        bottomMenu: false,
        appName: process.env.VUE_APP_NAME,
        appBrand:  process.env.VUE_APP_BRAND,
        footer: null,
        logo: process.env.VUE_APP_URL_LOGO ? process.env.VUE_APP_URL_LOGO : '',
        isTransparentHeader: true,
        isShowTab: true,
        isShowNotif: false,
        search: false,
        showNotif: false,
        message: {},
        popup: false,
        popupNotif: {
          title: 'Complete your information',
          message: 'We detected your personal information is not complete yet, It`s only takes 1 minute to complete your information.',
          action: 'Info',
          btnText: 'OK',
        }
      }
    },
    methods: {
      close () {
        this.$store.commit('setLoginDialog', false)
      },
      toLink (link) {
        window.open(link, '_blank');
      },
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name });
          this.setPanel(false)
        }
      },
      showNotifRating () {
        setTimeout(() => {
          if (this.$route.name === 'Home' || this.$route.name === 'Shop' || this.$route.name === 'Product') {
            this.showNotif = true
            this.message = {
              title: 'RATE AND REVIEW',
              message: 'Hi ' + this.userName + ', thank you for trusting on us. Help us to rate and review the product!' ,
              action: 'MyReview',
              btnText: 'GO Now'
            }
          }
        }, 500)
      },
      handleRoute (link){
        this.showNotif = false
        this.$router.push({name: link})
      },
      showBottomMenu () {
        this.bottomMenu = !this.bottomMenu
      },
      onResize () {
        this.$store.commit('setIsMobile', window.innerWidth < 920)
      },
      handleScroll () {
        let offsets = document.getElementById('content-wrap').getBoundingClientRect();
        if (offsets.top < -100 ) {
          this.isTransparentHeader = false
          this.isShowTab = false
        } else {
          if (this.search) {
            this.isTransparentHeader = false
          } else {
            this.isTransparentHeader = true
          }
          this.isShowTab = this.appBrand === '14' || false
        }
      },
      setMenus (response) {
        let items = []
        response.data.data.sidebar.map(item => {
          if (item.sub) {
            items.push({
              title: item.name,
              route: null,
              color: item.sidebar_color ? item.sidebar_color : '#000',
              id_brand: item.id_brand,
              active: false,
              items: item.sub.map(sub => {
                return {
                  title: sub.name,
                  route: sub,
                  color: sub.font_color ? sub.font_color : '#000',
                  id_brand: this.appBrand,
                  active: false,
                }
              })
            })
          } else {
            items.push({
              title: item.name,
              route: item.name,
              color: item.sidebar_color ? item.sidebar_color : '#000',
              active: false,
              id_brand: item.id_brand,
              items: null
            })
          }
        })
        this.$store.commit('setMenuItems', items)
      },
      getMenus () {
        this.$store.commit('setIsLoading', true)
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          is_child: this.activeTab && this.activeTab.tab !== 0 ? 'YES' : 'NO'
        }
        axios.get('/api/v2/pages/menu')
          .then(response => {
            this.footer = response.data.data.footer
            this.$store.commit('setFooter', this.footer)
            this.setMenus(response)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      openWhatsapp () {
        let data = this.footer.social_media.find(item => { return item.link && item.link.toLowerCase().includes('whatsapp') })
        if (data && data.link) {
          this.toLink(data.link)
        }
      },
      getFloatButtonColor () {
        return this.footer ? this.footer.color.background : ''
      },
      handleSearch () {
        this.search = true
        this.isTransparentHeader = false
      },
      handleSearchClose () {
        this.search = false
        this.handleScroll()
      },
      getNotif () {
        this.$store.commit('setIsLoading', true)
      
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
        }

        axios.get('/api/v1/pages/notification')
          .then(response => {
            if (response.data.status) {
              this.$store.commit('setNotifNumber', response.data.data.total)
              this.$store.commit('setReviewNumber', response.data.data.total_rating)
              if (response.data.data.total_rating) {
                // this.showNotifRating()
              }
            }
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      checkData () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.get('/api/v1/member/profile')
          .then(response => {
            this.$store.commit('setPersonalData', response.data.data)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      setIsShowNotif() {
        if (this.route === 'Checkout' || this.route === 'Cart') {
          this.isShowNotif = false
        } else {
          this.isShowNotif = false
        }
      },
      setIsShowTab() {
        if (this.appBrand === '14') {
          this.isShowTab = true
        } else {
          this.isShowTab = false
        }
      }
    },
    beforeDestroy () {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
        window.removeEventListener('scroll', this.handleScroll)
      }
    },
    mounted () {
      this.onResize()
      this.getMenus()
      setTimeout(() => {
        this.setIsShowNotif()
      }, 500)
      
      this.setIsShowTab()

      if (this.isLogin) {
        // this.getNotif()
        this.checkData()
      }

      this.$analytics.fbq.event('PageView')
      window.addEventListener('resize', this.onResize, { passive: true })
      window.addEventListener('scroll', this.handleScroll)
    }
  }
</script>
<style>

  @font-face {
    font-family: "Futura Light";
    src: url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.eot");
    src: url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/2167e76f00e569cc11b3665679996380.svg#Futura Light") format("svg")
  }
  
  @font-face {
    font-family: "Futura PT Medium";
    src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot");
    src: url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/3c6b0199ae7b57966132e83c939d77e2.svg#Futura PT Medium") format("svg");
  }

  #app {
    font-family: 'Helvetica' !important;
    position: relative;
    min-height: 100vh;
  }

  #content-wrap {
    padding-bottom: 1.0rem;    /* Footer height */
  }

  #footer {
    font-family: Nunito !important;
    position: absolute;
    padding: 0;
    bottom: 0;
    width: 100%;
    height: 2.5rem;           /* Footer height */
  }

  .footer-title-b {
    font-size: 16px;
    font-weight: bold;
  }

  .footer-title {
    font-size: 16px;
  }

  .footer-text {
    font-size: 14px;
  }

  .footer-title-b-local {
    font-family: "Futura PT Medium" !important;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: bold;
  }

  .footer-title-local {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
    font-size: 16px;
  }

  .footer-text-local {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
    font-size: 14px;
  }
  
  ul.sidebar-panel-nav {
    list-style-type: none;
    padding: 0 !important;
  }

  ul.sidebar-panel-nav > li {
    color: #000000;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 500;
    display: block;
    padding-bottom: 0.5em;
    cursor: pointer;
  }

  .nav-sub-c {
    list-style-type: none;
    padding: 0 !important;
  }

  .sub-c {
    font-size: 1rem !important;
    font-weight: normal !important;
    cursor: pointer;
    margin-bottom: 0.2rem;
  }

  .sub-c:hover {
    font-weight: bolder !important;
  }

  .active-sub {
    font-weight: 900 !important;
    background-color: #C0C0C0;
  }

  .active {
    color: #000 !important;
    font-weight: 900 !important;
    font-size: 1.5rem !important;
    background-color: #fafafa;
  }

  .v-list-item {
    padding: 0 10px !important;
  }

  .footer {
    position: relative !important;
    bottom: 0px;
  }

  .custom-p {
    font-family: "Futura Light" !important;
    letter-spacing: 2px;
  }

  .img-logo {
    width: 100%;
    max-width: 120px;
    cursor: pointer;
  }

  .payment-method {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
  }

  .category-img {
    max-width: 100%;
    height: auto;
    box-shadow: 1px 2px #c0c0c0;
    border-radius: 10px;
    /* width: 300px;
    height: 300px;
    object-fit: cover; */
    cursor: pointer;
  }

   .float-button {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 100;
  }

  .float-button-dekstop {
    position: fixed;
    top: 50%;
    right: 5%;
    /* z-index: 100; */
  }

  .page-counter{
    position: absolute;
    top: 3%;
    right: 7%;
    width: 40px;
    padding: 5px;
    background-color: rgba(105,105,105);
    color: #fff;
    font-size: 12px;
  }

  .search-content {
    position: fixed;
    left:0;
    z-index: 999;
  }

  .noscroll {
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* Scroll bar */

  /* ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #c0c0c0;
  }

  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  } */

</style>
