<template>
  <v-card class="search">
    <v-layout wrap>
      <v-flex xs12 class="search-div">
        <v-form
          ref="form"
          class="ml-3 mr-3"
        >
          <v-text-field
            v-model="search"
            class="mt-4 mb-4"
            outlined
            dense
            ref="search"
            placeholder="Search Product"
            append-icon="mdi-close"
            @input="searchData()"
            :hide-details=true
            @click:append="$emit('close')"
          ></v-text-field>
        </v-form>
      </v-flex>
      <v-flex xs12 class="search-div result">
        <v-layout wrap>
          <v-flex class="xs12 sm6 md4 pt-5 pb-5" v-for="(item, index) in shop" :key="index">
          <v-layout wrap @click="goTo(item)">
              <v-flex xs2 class="shop-item pl-3 pr-3">
                <img v-bind:src="item.url_image" class="shop-img" :alt="item.product_code" @error="handleImageError">
              </v-flex>
              <v-flex xs10>
                <p class="text"> <b>{{ item.product_name }} </b></p>
                <p v-if="item.harga_coret && item.harga_coret !== '0'" class="text-through"> {{ addComma(item.harga_coret) }} IDR </p>
                <p class="text"> {{ item.selling_price === 0 ? '???' : addComma(item.selling_price) + ' IDR' }}  </p>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-if="done && shop.length === 0" class="xs12 sm4 md4 pa-5">
             <p class="text"> <b>Product not found </b></p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'SearchComponent',
  data () {
    return {
      search: '',
      done: false,
      shop: []
    }
  },
  methods: {
    focusInput() {
      setTimeout (() => {
        this.$refs.search.focus()
      }, 100)
    },
    handleImageError(event) {
      event.target.src = "https://spaces.ilfen.co/assets/image/default.png";
    },
    goTo (item) {
      this.$emit('close')
      this.toProduct(item)
    },
    searchData () {
      if (this.search && this.search.length >= 3) {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          key: this.search
        }
        axios.get('/api/v1/pages/search')
          .then(response => {
            this.shop = response.data.data
          })
          .catch(error => {
            this.shop = []
            this.message = error.response.data.api_msg
          })
          .finally(() => {
            this.done = true
            this.$store.commit('setIsLoading', false)
          })
      } else {
        this.shop = []
        this.done = false
      }
    } 
  },
  mounted () {
    this.focusInput()
  }
}
</script>

<style scoped>
.search {
  width: 100%;
}
.search-div {
  background-color: #fff;
}
.result {
  max-height: 350px;
  overflow-y: auto;
}
.shop-item {
  position: relative;
  text-align: center;
}
.shop-img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.shop-img:hover {
  opacity: 0.5;
}
.shop-img-m {
  max-width: 100%;
  height: auto;
}
.text{
  font-size: 0.8rem;
  margin:0;
}
.text-through{
  font-size: 0.8rem;
  color: #808080;
  text-decoration: line-through;
  margin: 0;
}
</style>